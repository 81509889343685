import React, { useState } from "react"

import { AiFillWarning } from "react-icons/ai"

import { WarningSection,
         WarningClose,
         WarningContainer,
         WarningHeader,
         WarningIcon,
         WrapTitle,
         WarningTitle,
         WarningSubtitle,
         WarningContent,
         WarningText,
         WarningTextDestak,
         WarningBrand,
         WarningFooter } from "./styles"

const Warning = () => {

   const [isDisable, setIsDisable] = useState("")

   const handleCloseWarning = (event) => {
      event.preventDefault()
      setIsDisable("-isDisable")
   }

   return(
      <WarningSection className={isDisable}>
         <WarningContainer>
            <WarningClose onClick={e => handleCloseWarning(e)} />
            <WarningHeader>
               <WarningIcon>
                  <AiFillWarning size="40px" color="#F57C00"/>&nbsp;
               </WarningIcon>
               <WrapTitle>
                  <WarningTitle>
                     CUIDADO
                  </WarningTitle>
                  <WarningSubtitle>
                     COM OS GOLPES DO BOLETO FALSO
                  </WarningSubtitle>
               </WrapTitle>
            </WarningHeader>
            <WarningContent>
               <WarningText>
                  Gostaríamos de alertá-los sobre uma prática fraudulenta que tem se tornado cada vez mais comum, <b>os boletos falsos.</b>               
               </WarningText>
               <WarningText>
                  Nossas cobranças o favorecido sempre será em nome da <b>ELETROSIL INDUSTRIA METALURGICA LTDA - CNPJ 60.848.058/0001-11</b>, se aparecer algo diferente disso <b>NÃO</b> finalize o pagamento.<br/>
                  Trabalhamos somente com a cobrança do <b>Banco Itaú</b>, desta forma, qualquer boleto que recebam que não seja deste banco deve ser ignorado.<br/>
                  Todos os e-mails da <b>ELETROSIL INDUSTRIA METALURGICA LTDA</b> possuem o domínio <b>@eletrosil.com.br</b>
               </WarningText>
               <WarningText>
                  Caso receba boleto de outro banco ou algum contato mencionando a necessidade de troca de boleto, entrem em contato pelo <b>Telefone (11) 2671-7088 ou pelo e-mail: financeiro@eletrosil.com.br.</b>
               </WarningText>
               <WarningText>
                  Obrigado!
               </WarningText>
               <WarningTextDestak>
               </WarningTextDestak>
            </WarningContent>
            <WarningFooter>
               {/* <WarningText></WarningText> */}
               <WarningBrand/>
            </WarningFooter>
         </WarningContainer>
      </WarningSection>
   )
}

export default Warning


// {intl.formatMessage({ id: "contact.title" })}
