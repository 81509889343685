import styled from "styled-components"
import media from "../../styles/breakPoints"

import Section from "../../objects/Section"
import Container from "../../objects/Container"

import ButtonClose from "../../objects/ButtonClose"
import Brand from "../../objects/Brand"


const WarningSection = styled(Section)`
   position: absolute;
   display: block;
   top: 0;
   left: 0;
   background-color: rgba(0,0,0,0.8);
   width: 100vw;
   height: 100vh;
   z-index: 99999;
   padding: 16px;
   &.-isDisable {
      display: none;
   }
`

const WarningClose = styled(ButtonClose)`
   position: absolute;
   color: #F57C00;
   right: 8px;
   top: 8px;
`


const WarningContainer = styled(Container)`
   position: relative;
   width: 100%;
   max-width: 500px;
   display: block;
   background-color: #ffebce;
   padding: 16px;
   border-radius: 10px;
   top: 50%;
   transform: translateY(-55%);

   ${media.greaterThan("md")`
      padding: 32px;
   `}

   ${media.greaterThan("lg")`
      max-width: 700px;
   `}

`

const WarningHeader = styled.div`
   display: flex;
   margin-top: 30px;
   padding-bottom: 10px;

   ${media.greaterThan("md")`
      padding-bottom: 20px;
   `}

`

const WarningIcon = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`

const WrapTitle = styled.div`
   display: block;
`

const WarningTitle = styled.h3`
   display: flex;
   justify-content: flex-start;
   align-items: center;
   font-size: 16px;
   margin: 0 0 5px 10px;
   padding: 0;

   ${media.greaterThan("md")`
      font-size: 20px;
   `}
   ${media.greaterThan("lg")`
      font-size: 24px;
   `}

`

const WarningSubtitle = styled.h4`
   font-size: 12px;
   font-weight: bold;
   line-height: 16px;
   margin: 0 0 0 10px;

   ${media.greaterThan("md")`
      font-size: 14px;
      /* width: 85%; */
   `}
   ${media.greaterThan("lg")`
      font-size: 16px;
      /* width: 85%; */
   `}
`

const WarningContent = styled.div`
   display: flex;
   flex-grow: 1;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
`


const WarningText = styled.p`
   font-size: 12px;
   &:last-child {
      margin-bottom: 0;
   }

   ${media.greaterThan("md")`
      font-size: 14px;
   `}
   ${media.greaterThan("lg")`
      font-size: 16px;
   `}

`

const WarningTextDestak = styled.p`
   font-size: 16px;
   font-weight: 700;
   text-align: center;
   width: 100%;

   ${media.greaterThan("md")`
      font-size: 20px;
   `}
   ${media.greaterThan("lg")`
      font-size: 24px;
   `}

`

const WarningBrand = styled(Brand)`
   width: 120px;
   margin-top: 0; //20px;
   margin: 0 auto;
`

const WarningFooter = styled.div`
   width: 100%;
   display: block;
   text-align: center;
   margin-top: 0; //40px;
`

export {
   WarningSection,
   WarningClose,
   WarningContainer,
   WarningHeader,
   WarningIcon,
   WrapTitle,
   WarningTitle,
   WarningSubtitle,
   WarningContent,
   WarningText,
   WarningTextDestak,
   WarningBrand,
   WarningFooter
}