import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import Layout from "../components/Layout";
import SEO from "../components/seo"
import BannerCarousel from "../components/BannerCarousel"
import CompanySection from "../components/CompanySection"
import ProductLineSection from "../components/ProductLineSection"
import BudgetSection from "../components/BudgetSection"
import Warning from "../components/Warning"

const IndexPage = () => {

   const intl = useIntl();

   const content = useStaticQuery(
      graphql`
         query {
            imgBanners: allFile (
               filter: {relativeDirectory: { eq: "banner-carousel" }},
               sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
                  edges {
                     node {
                        childImageSharp {
                           fluid(maxWidth: 1200) {
                              ...GatsbyImageSharpFluid
                           }
                        }
                     }
                  }
            }
         }
      `
    )

   return (
      <Layout>
         <SEO 
            lang={intl.locale}
            title={intl.formatMessage({ id: "navbar.item1" })}
            keywords={[ `indústria metalúrgica em São Paulo`, `eletromecânico`, `chave elétrica`, `chave de emergência`, `contato elétrico`, `relé`, `conector`, `tomada industrial`, `contato elétrico`, `bobina magnética`, `siderurgia`, `mineração`,`celulose`, `ferrovia`, `engenharia` ]}
         />
         <Warning />
         <BannerCarousel 
            element="glide-hero" 
            children={content.imgBanners.edges}
         />
         <CompanySection />
         <ProductLineSection />
         <BudgetSection />
      </Layout>
   )

};


export default IndexPage;


/* COMPONENTE MODAL PARA AVISOS E COMUNICADOS NA ABERTURA DO SITE */
/* importar componente WARNING: import Warning from "../components/Warning" */
/* renderizar componente <Warning /> logo acima do BannerCarousel */